<template>
  <base-form :settings="settings"/>
</template>

<script>
export default {
	name: 'login-form',

	data() {
		return {
			user: {},
			settings: {
				
				title: () => 'Вход',

				actions: [
					{
						label: () => 'Регистрация',
						navigate: '/#/registration/'
					},
					{
						label: () => 'Войти',
						href: '/api/v1/users/login',
						navigate: '/'
					}
				],

				entity: {

					defaultFocus: (user) => user.email ? 'password' : 'email',

					fields: {
						email: {
							label: () => 'Электронная почта',
							icon: 'mdi-account',
							type: 'text',
							trim: true,
							validate: {
								required: true,
								maxLength: 50
							}
						},
						password: {
							label: () => 'Пароль',
							icon: 'mdi-lock',
							type: 'password',
							validate: {
								required: true,
								maxLength: 50
							}
						}
					}
				}
			}
		}
	}
}
</script>